import React from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'


const spanStyle = {
  padding: '20px',
   
  color: '#111111'
}

const divStyle = {
  display: 'flex',
  flexDirection : 'column',
  alignItems: 'left',
  justifyContent: 'left',
  backgroundSize: 'cover',
  backgroundRepeat : 'no-repeat',
  height: '60vw'
}


export default function Slideshow(props){

  const slideImages = [
  {
    url: `${props.img1}`,
    caption: ''
  },
  {
    url: `${props.img2}`,
    caption: ''
  },
  {
    url: `${props.img3}`,
    caption: ''
  },
   {
    url: `${props.img4}`,
    caption: ''
  },
  {
    url: `${props.img5}`,
    caption: ''
  },
  {
    url: `${props.img6}`,
    caption: ''
  },
  {
    url : '../../splinehobbing.gif',
    caption:''
  },
  {
    url : '../../heattreatment.gif',
    caption:''
  },
  {
    url : '../../grinding.gif',
    caption:''
  },
  {
    url : '../../lasermarking.gif',
    caption:''
  },

];
    return (
      <div className="slide-container">
        <Slide>
         {slideImages.map((slideImage, index)=> (
            <div key={index}>
               <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})`}}>
               
                <span style={spanStyle}>{slideImage.caption}</span>
              </div>
            </div>
          ))} 
        </Slide>
      </div>
    );
}