import React from 'react';
import Item from './Item.js';
import {productList} from "./productList.js";



export default function ItemDisplayer({value}){

const List = productList;

const retList = [];
//console.log(value);
List.map((Val,indx)=>{

	if(Val.PartNo.toString().toLowerCase() === value || Val.Name.toString().toLowerCase() === value || (Val.Car.search(value)!==-1) )
	{
		//console.log(Val);
		retList.push(Val);
	} 

})

if(retList.length === 0)
{	
	return(<div> <div className = "Item-not-found"> <img src = "../../placeholder.png"/> <h1> sorry item {value} not found </h1> </div><div className = "BackToContactUs"> <h2> please contact us for make to order using the form on home page </h2> </div></div>);
}
else{
return(
		<>
		{retList.map((Val,indx)=>{
			return(
				<Item val = {Val}/>

			)

		})}

		</>

);
}

}