import React,{useEffect} from 'react';


import Body from './components/body.js';
import AboutUs from './components/AboutUS/AboutUs.js'
import Facilities from './components/Facilities/Facilities.js'
import ProductListing from './components/ProductListing/ProductListing.js'
import Login from './components/Login/Login.js'
import ItemDisplayer from './components/ProductListing/ItemDisplayer.js'

export default function ShowRunner({token,setToken,val,setVal}){

//useEffect(()=>{console.log(val)},[token])


if(token === 0)
{
	return( <div>
				<Body img1 = {"./finishedlasermarkedlogo.JPG"} img2 = {"./gs.gif"} img3 = {"./contact-bg.jpg"} img4 = {"./circularsaw.gif"} img5 =  {'./upsetter.gif'} img6 = {'./turning2.gif'} img7 = {'./oerlikon.gif'}/>
			</div>)
}

else if(token === 1)
{
	return( <div>
			<AboutUs img1 = {"./plant2.jpg"} img2 = {"./gears.png"}/>
			</div>)
}

else if(token === 2)
{
	return(	<div>
				<Facilities img1 = {"./placeholder.png"} presses = {'./Presses.jpg'}/>
			</div>
			)
}

else if(token === 3)
{
	return(
			<div>
				<ProductListing setVal = {setVal} val = {val} setToken = {setToken} img1 = {"./TATAlogo.webp"} img2={"./Faitlogo.png"} img3 = {"./BedfordLogo.png"} img4 = {"./canterlogo.png"} img5 = {"./belaruslogo.png"} img6={"./Hinologo.png"} img7={"./mazdalogo.png"} img8 = {"./mercedeslogo.png"} img9 = {"./MFlogo.png"} img10 ={"./ZFlogo.png"}/>
			</div>
	)
}

else if(token === 4)
{
	return(
			<div>
			
			<Login/>
			</div>
	)
}

else if(token === 5)
{
	return(
		<div>
		<ItemDisplayer value = {val} />
		</div>
	)
}
};

