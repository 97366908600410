import React from 'react';
import { FaWhatsapp } from "react-icons/fa";
import { FaPhone } from "react-icons/fa6";
import { HiOutlineMail } from "react-icons/hi";



export default function Footer(props){
	return(
			<>
			<footer className ="footer">
				<div className = "footer1">
				<div className = "logo-footer-container">
					<div className = "logo-footer">
					<img src = {props.serv} />
				</div>
				<h2> OUR BRANDS </h2>
				<div className = "logo-footer"> 
				
				<img src = {props.serv2}/>
				</div>
				<p> the above brands are a Trade Mark of VV Enterprises </p>
				</div>
				<div className = "contact-details-footer">
				<div className = "contact-details-heading" ><h2> Contact Details </h2></div>

				<p> <HiOutlineMail /> : info@vvgears.com </p>

				<p> <HiOutlineMail /> : vv@vvgears.com </p>
				<p> <FaWhatsapp /> | <FaPhone /> : +91 9810509910 </p>
				<p> <FaWhatsapp /> | <FaPhone /> : +91 9910509910 </p> 
				
				</div>
				</div>
				<div className = "address-footer">
				<h2> Visit Us </h2>
				<iframe width="95%" height="200" frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" src="https://maps.google.com/maps?width=100%25&amp;height=600&amp;hl=en&amp;q=28.21369588995905,%2076.8428468267772+(V.V.%20Enterprises)&amp;t=&amp;z=14&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"><a href="https://www.gps.ie/">gps devices</a></iframe>
				<div className = "address-div">
				<p> Plant 1: B-479, RIICO Phase 1, Bhiwadi, Rajasthan, 301019, India</p>
				<p> Plant 2: B-483A, RIICO Phase 1, Bhiwadi, Rajasthan, 301019, India</p>
				</div>
				</div>

			</footer>
   

			</>
		);
}